/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "~src/assets/styles/colors";
// @import '~@ctrl/ngx-emoji-mart';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: Poppins, "Helvetica Neue", sans-serif;
}

.emoji-mart {
  max-width: 100%;

  .emoji-mart-bar:last-child {
    display: none;
  }
  .emoji-mart-scroll {
    height: 375px;
  }
  .emoji-mart-category-label {
    color: $primary-text;
    font-weight: 600;
    font-size: 10px;
  }
  .emoji-mart-search, .emoji-mart-search-2 {
    input {
      font-size: 10px;
      font-weight: 600;
    }
  }
}



.emoji-mart-anchor-selected {
  color: $primary !important;
  // svg {
  //   color: $primary;
  // }
  .emoji-mart-anchor-bar {
    background-color: $primary !important;
  }
}

.cal-open-day-events {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  //flex-wrap: wrap;
  overflow-x: auto !important;
}
.cal-open-day-events .card {
  background: white;
  min-width: 60px;
  width: 60px;
  min-height: 80px;
  height: 60px;
  margin: 0 5px;
  font-size: 10px;
}
.cal-month-view .cal-day-badge {
  background-color: $primary;
}
.cal-events {
  display: none !important;
}
.cal-month-view .cal-day-cell.cal-today .cal-day-number {
  font-size: 1.2em;
  color: $primary;
  font-weight: 700;
  opacity: 0.8;
}
.cal-month-view .cal-day-cell.cal-today {
  background-color: var(--contrastColor) + '26';
}
.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
  color: var(--contrastColor);
}
.cal-cell.cal-day-cell:hover {
  border: 2px solid var(--primaryColor);
  color: var(--primaryColor);
  cursor: pointer;
  .cal-day-number {
    color: var(--primaryColor);
  }
}
.cal-month-view {
  background-color: $background;
}

.cal-month-view .cal-cell-row:hover {
  background-color: var(--backgroundColor);
}

.cal-month-view .cal-day-number {
  margin: 0 !important;
  margin: 0 3px !important;
  // position: absolute;
}
.cal-cell-row {
  min-height: 50px;
  height: 50px;
}

mwl-calendar-month-cell.cal-cell.cal-day-cell.cal-past.cal-in-month,
mwl-calendar-month-cell.cal-cell.cal-day-cell.cal-future.cal-in-month,
mwl-calendar-month-cell.cal-cell.cal-day-cell.cal-today.cal-in-month.cal-has-events {
  min-height: 50px;
  height: 50px;
  .cal-day-badge {
    // margin-top: 25px !important;
    transform: translateY(22.5px) translateX(2.5px);
    // margin-left: 2.5px !important;
  }
}
.cal-cell-top span:first-child:not(.cal-day-number) {
  margin: 0 !important;
}
.cal-month-view .cal-cell-top {
  min-height: 50px;
}
.cal-month-view .cal-day-cell {
  min-height: 50px;
  font-size: 12px;
}
.cal-cell-row.cal-header {
  min-height: 35px;
  height: 35px;
  font-weight: 300;
  color: $primary-text;
}
.cal-week-view .cal-event {
  color: white;
}

.cal-month-view .cal-header .cal-cell {
  font-size: 12px;
  font-weight: 500;
  padding: 10px 0;
}